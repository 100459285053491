/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_dynamodb_all_tables_region": "us-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "pay-to-see-db",
            "region": "us-west-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "nodeapi",
            "endpoint": "https://tuui63hhf5.execute-api.us-west-1.amazonaws.com/dev",
            "region": "us-west-1"
        }
    ]
};


export default awsmobile;
